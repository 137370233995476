import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "./auth.service";
import {SpinnerService} from "../../spinner/spinner.service";
import {Observable} from "rxjs";
import {UserService} from "../../user/user.service";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  loading$: Observable<boolean>;
  public show: boolean = false;
  public loginForm: FormGroup = this.fb.group({
    id: ['', [Validators.required, Validators.pattern("^\\d{3,}$")]],
    password: ['', Validators.required],
  });

  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private userService: UserService,
              private spinnerService: SpinnerService,
              private translate: TranslateService,) {
    this.loading$ = this.spinnerService.loading$;
  }

  showPassword() {
    this.show = !this.show;
  }

  submit() {
    this.authService.login(this.loginForm.value["id"].toString(), this.loginForm.value["password"])
      .subscribe({
        next: (response) => {
          if (response && response.token) {
            this.navigateToMain();
          }
        }
      });
  }

  navigateToMain() {
    this.router.navigate(['/main'])
  }

  onForgetPasswordClicked() {
    console.log("onForgetPasswordClicked");
    const userId = this.loginForm.value["id"].toString();
    this.userService.resetPassword(userId).subscribe({
      next: async () => {
        await Swal.fire({
          text: this.translate.instant('auth.passwordResetSent', {userId: userId}),
          icon: "success"
        });
      },
      error: async () => {
        await Swal.fire({
          text: this.translate.instant('auth.passwordResetFailed'),
          icon: "error"
        });
      }
    });
  }

  get isForgetPasswordDisabled(): boolean {
    return this.loginForm.value["id"].toString() === '';
  }
}
