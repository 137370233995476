<div class="container-fluid p-0">
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="login-card login-dark">
        <div>
          <div class="logo">
            <img alt="login-page" src="assets/images/logo/zzapp-logo.webp">
          </div>
          <div class="login-main">
            <form (ngSubmit)="submit()" [formGroup]="loginForm" class="theme-form">
              <h3>{{ 'auth.mainTitle' | translate }}</h3>
              <p>{{ 'auth.subTitle' | translate }}</p>
              <div class="form-group">
                <label class="col-form-label">{{ 'auth.id' | translate }}</label>
                <input class="form-control"
                       formControlName="id"
                       id="id"
                       appOnlyDigits
                       required="true"
                       type="text">
                @if (loginForm.controls['id'].touched && loginForm.controls['id'].errors?.['required']) {
                  <div class="text text-danger mt-1">
                    {{ 'auth.idRequired' | translate }}
                  </div>
                }
              </div>
              <div class="form-group">
                <label class="col-form-label">{{ 'auth.password' | translate }}</label>
                <div class="form-input position-relative">
                  <input [type]="show ? 'text' : 'password'"
                         class="form-control"
                         formControlName="password"
                         id="password"
                         placeholder="*********"
                         required='true'
                         type="password">
                  @if (show) {
                    <div class="show-hide" (click)="showPassword()"><span class="Hide"></span></div>
                  } @else {
                    <div class="show-hide" (click)="showPassword()"><span class="show"></span></div>
                  }

                  @if (loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']) {
                    <div
                      class="text text-danger mt-1">
                      {{ 'auth.passwordRequired' | translate }}
                    </div>
                  }
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-body-tertiary" for="checkbox1">{{ 'auth.rememberPassword' | translate }}</label>
                </div>
                <a
                  (click)="onForgetPasswordClicked()"
                  class="link"
                  [class.disabled]="isForgetPasswordDisabled">{{ 'auth.forgotPassword' | translate }}</a>
                <button mat-flat-button
                        [disabled]="loginForm.invalid || loginForm.pristine"
                        class="w-100"
                        type="submit">{{ 'auth.signIn' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  @if (loading$ | async) {
    <div class="custom-loader"></div>
  }
</div>
